import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export type SupportedLocales = typeof supportedLocales[number];
export const supportedLocales = ["en", "nl"] as const;

i18n
	.use(
		new Backend(null, {
			loadPath: (languages, namespaces) => `/i18n/${languages}.json`,
		})
	)
	.use(initReactI18next)
	.init({
		supportedLngs: supportedLocales,
		fallbackLng: "nl",
	});

export default i18n;
