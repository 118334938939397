import React from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";

const flatten = (routes: Routes): Routes =>
	Object.fromEntries(
		Object.entries(routes).flatMap(([key, route]) =>
			route.subroutes
				? [[key, route], ...Object.entries(flatten(route.subroutes))]
				: [[key, route]]
		)
	);

type Routes = Record<string, Route>;

type Route = Readonly<{
	url: string;
	component: React.LazyExoticComponent<any>;
	authorised?: () => boolean;
	subroutes?: Routes;
}>;

export const routes = flatten({
	home: {
		url: "/",
		component: React.lazy(() => import("pages/home/home-page")),
	},
	login: {
		url: "/login",
		component: React.lazy(() => import("pages/login/login-page")),
	},
	register: {
		url: "/register",
		component: React.lazy(
			() => import("pages/register-user/register-user-page")
		),
	},
	export: {
		url: "/export",
		component: React.lazy(() => import("pages/export/export-page")),
	},
});

const NotFound = React.lazy(() => import("pages/not-found/not-found-page"));

export const Routes = () => (
	<RouterRoutes>
		{Object.entries(routes).map(([key, route]) => (
			<Route key={key} path={route.url} element={<route.component />} />
		))}
		<Route path="*" element={<NotFound />} />
	</RouterRoutes>
);

/** Get a route by its key, to prevent typos and to get
 * type errors when a route changes.
 */
export const route = (
	// TODO: Find out how to get a union of all keys instead of `string`.
	key: keyof typeof routes,
	params?: Record<string, { toString(...args: any[]): string }>
) =>
	flatten(routes)[key].url.replace(
		/:[^\/]+/g,
		match => params?.[match.slice(1)]?.toString() ?? match
	);
