import { ReactComponent as ChipprLogo } from "assets/icons/chippr-logo.svg";
import { CrossAxisAlignment, MainAxisAlignment } from "layout/flex";
import { Gap, Padding } from "layout/layout";
import Row from "layout/row";
import { useTranslation } from "react-i18next";
import { supportedLocales } from "lib/i18n";
import { Link } from "react-router-dom";
import { route } from "lib/router";

type Props = {};

export const MenuHeader = (props: Props) => {
	const { t, i18n } = useTranslation();

	return (
		<Row
			as="header"
			gap={Gap.Medium}
			padding={Padding.Medium}
			mainAxisAlignment={MainAxisAlignment.SpaceBetween}>
			<Link to={route("home")!}>
				<img id="brand" src="sonuts-02.png" />
			</Link>
			<select
				className="LanguageSelector"
				defaultValue={i18n.resolvedLanguage}
				onChange={e => {
					i18n.changeLanguage(e.target.value);
				}}>
				{supportedLocales.map(locale => (
					<option key={locale} value={locale}>
						{t(`languages.${locale}`)}
					</option>
				))}
			</select>
		</Row>
	);
};
