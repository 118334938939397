import { useState } from "react";
import useLocalStorageSyncedState from "./local-storage";
import useWindowSize from "./window-size";
type UseAuthenticationParams<Authentication> = Readonly<{
	login: (accessToken: string, refreshToken: string) => Promise<Authentication>;
	logout: () => Promise<void>;
	refresh: (auth: Authentication, username: string) => Promise<Authentication>;
	isTokenValid: (auth: Authentication) => boolean;
}>;

const makeUseAuthentication =
	<Authentication>({
		login: _login,
		logout: _logout,
		refresh: _refresh,
		isTokenValid: _isTokenValid,
	}: UseAuthenticationParams<Authentication>) =>
	() => {
		const [auth, setAuth] = useLocalStorageSyncedState<Authentication>("auth");
		const login = (...args: Parameters<typeof _login>) =>
			_login(...args).then(auth => {
				setAuth(auth);
				return auth;
			});
		const logout = (...args: Parameters<typeof _logout>) =>
			_logout(...args).then(() => {
				setAuth(undefined);
				window.location.reload();
			});
		const refresh = (auth: Authentication, username: string) =>
			_refresh?.(auth, username).then(auth => {
				setAuth(auth);
				return auth;
			});
		const isTokenValid = (auth: Authentication) => _isTokenValid(auth);
		return { auth, login, logout, refresh, isTokenValid };
	};

export default makeUseAuthentication;
