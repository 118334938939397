import Column from "layout/column";

type ErrorPageProps = Readonly<{
	error: unknown;
}>;

const ErrorPage = ({ error }: ErrorPageProps) => {
	//

	return <Column>{(error as any).toString()}</Column>;
};

export default ErrorPage;