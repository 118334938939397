import { SetStateAction, useState } from "react";

export const getFromLocalStorage = <T>(key: string): T | undefined => {
    const item = localStorage.getItem(key);
    return item && JSON.parse(item);
};

const useLocalStorageSyncedState = <T>(key: string, defaultValue?: T) => {
    const [state, setState] = useState(
        () => getFromLocalStorage<T>(key) ?? defaultValue
    );

    const value = (state ?? getFromLocalStorage<T>(key))!;

    const set = (value: SetStateAction<T | undefined>): void => {
        const newState = value instanceof Function ? value(state) : value;
        if(newState !== undefined) {
            localStorage.setItem(key, JSON.stringify(newState));
        }else{
            localStorage.removeItem(key);
        }
        setState(newState);
    };

    const clear = (): void => {
        localStorage.removeItem(key);
        setState(undefined);
    };

    return [value, set, clear] as const;
};

export default useLocalStorageSyncedState;