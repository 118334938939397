/** A function that logs a value to the console.
 *
 * Useful for dropping in-place in some arbitrary expressions.
 */
export const log = <T>(value: T) => {
	console.log(value);
	return value;
};

/** Returns a Promise that resolves to _nothing_ after _at least_ `ms` milliseconds. */
export const wait = (ms: number) =>
	new Promise<void>(resolve => setTimeout(resolve, ms));

/** Debounces a function `f`, returning a function which will invoke _**only once**,
 * even if invoked many times, after at least `ms` milliseconds._
 *
 * This function has the side effect that any invocation of `f` is delayed by at least `ms` milliseconds.
 *
 * Example:
 * ```ts
 * // Potentially straining request
 * const search = (query: string) => console.log(`Searching ${query}...`);
 * // Wait at least 100ms after the last invocation attempt before actually invoking
 * const debouncedSearch = debounce(100)(search);
 * for (let i = 0; i < 100; i++) {
 *   debouncedSearch("test1");
 * }
 * // > "Searching test1..." is only logged once!
 *
 * for (let i = 0; i < 100; i++) {
 *   if (i % 100 === 0) await wait(10); // wait 10ms every 10th invocation
 *   debouncedSearch("test2");
 * }
 * // > "Searching test2..." is logged ~10 (depending on your hardware) times (100/10).
 * ```
 *
 * You can pool debounced functions by partially applying a timeout `ms`,
 * and using that function to wrap any function `f`.
 *
 * `debounce` differs from `throttle` because `debounce` ensures any function `f`
 * is invoked _only once_ if the time in milliseconds since last invocation is less than `ms`,
 * whereas `throttle` will be invoked about `ms / (n * executionTime(f))`
 * times where `n` is the total amount of invocations.
 */
export const debounce = (ms: number) => {
	let timeout: number | undefined;
	return <T extends (...args: any) => any>(f: T) =>
		((...params: Parameters<T>) => {
			window.clearTimeout(timeout);
			timeout = window.setTimeout(f.bind(null, ...(params as any)), ms);
		}) as T;
};

export type Mutable<T> = {
	-readonly [P in keyof T]: T[P];
};
