import Column from "layout/column";
import Flex, {
	CrossAxisAlignment,
	FlexDirection,
	MainAxisAlignment,
} from "layout/flex";
import { Gap, Padding } from "layout/layout";
import "layout/layout.scss";
import makeUseAuthentication from "lib/authentication";
import React from "react";
import * as http from "lib/network";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { route } from "lib/router";
import useWindowSize from "lib/window-size";
import useLocalStorageSyncedState from "lib/local-storage";
import { getFromLocalStorage } from "lib/local-storage";
import "./login.scss";
export const useAuth = makeUseAuthentication({
	login: (username, password) =>
		http.post("oauth2/token", {
			username,
			password,
			grantType: "password",
			clientId: "05004bd2-18d9-402f-9a1b-673fcf1d46e7",
		}),
	logout: () => Promise.resolve(),
	refresh: (auth: any, username: string) =>
		http.post("oauth2/token", {
			grantType: "RefreshToken",
			clientId: "05004bd2-18d9-402f-9a1b-673fcf1d46e7",
			refreshToken: auth.refreshToken,
			username: username,
		}),
	isTokenValid: auth => {
		const { accessToken } = auth;
		const token: {
			iss: string;
			sub: string;
			aud: string;
			iat: number;
			exp: number;
		} = JSON.parse(atob(accessToken.split(".")[1]));
		const now = new Date();
		return token.exp > now.getTime() / 1000;
	},
});

const LoginPage = () => {
	const [username, setUsername] =
		useLocalStorageSyncedState<string>("username");

	const [error, setError] = useState<Boolean>();
	const { login, logout } = useAuth();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const formData = new FormData(event.currentTarget);
		const isStringType = (x: string | File | null) =>
			typeof x === "string" ? x : "";
		const username = isStringType(formData.get("username"));
		const password = isStringType(formData.get("password"));

		login(username, password)
			.then(auth => {
				if (auth) {
					navigate(route("export"), { replace: true });
					setUsername(username);
					window.location.reload();
				} else {
					setError(true);
				}
			})
			.catch(() => {
				setError(true);
			});
	};

	return (
		<Flex
			grow
			direction={FlexDirection.Column}
			mainAxisAlignment={MainAxisAlignment.Center}
			crossAxisAlignment={CrossAxisAlignment.Center}
			gap={Gap.Medium}>
			<Column
				id="login"
				as="form"
				onSubmit={handleSubmit}
				padding={Padding.Huge}
				gap={Gap.Small}>
				<h2>{t("pages.login.login")}</h2>

				<Flex direction={FlexDirection.Column}>
					<label htmlFor="username">{t("pages.login.username")}</label>
					<input required type="email" name="username" id="username" />
				</Flex>
				<Flex direction={FlexDirection.Column}>
					<label htmlFor="password">{t("pages.login.password")}</label>
					<input required type="password" name="password" id="password" />
				</Flex>
				{error && <p className="error">{t("pages.login.error")}</p>}
				<button type="submit">{t("pages.login.login")}</button>
			</Column>
		</Flex>
	);
};

export default LoginPage;
