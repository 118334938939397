import Endpoints from "models/endpoints";
import { useAuth } from "pages/login/login-page";
import { getFromLocalStorage } from "lib/local-storage";
import { Toast } from "react-toastify/dist/components";
import { toast } from "react-toastify";
type GetRequestParams = Readonly<{
	url: string;
	method: "GET" | "DELETE";
	decode: keyof typeof decoders;
	authentication?: { accessToken: string; refreshToken: string };
	refresh: (
		auth: { accessToken: string; refreshToken: string },
		username: string
	) => Promise<{
		accessToken: string;
		refreshToken: string;
	}>;
	isTokenValid: (auth: {
		accessToken: string;
		refreshToken: string;
	}) => boolean;
}>;

type DataRequest<T> = Readonly<{
	url: string;
	method: Exclude<keyof Endpoints, GetRequestParams["method"]>;
	body: T;
	encode: keyof typeof encoders;
	decode: keyof typeof decoders;
	authentication?: { accessToken: string; refreshToken: string };
	refresh: (
		auth: { accessToken: string; refreshToken: string },
		username: string
	) => Promise<{
		accessToken: string;
		refreshToken: string;
	}>;
	isTokenValid: (auth: {
		accessToken: string;
		refreshToken: string;
	}) => boolean;
}>;

export const useNetwork = () => {
	const { auth, refresh, isTokenValid } = useAuth();
	const _get: typeof get = (url, options) =>
		get(url, {
			...options,
			authentication: auth,
			refresh: refresh,
			isTokenValid: isTokenValid,
		});
	const _post: typeof post = (url, body, options) =>
		post(url, body, {
			...options,
			authentication: auth,
			refresh: refresh,
			isTokenValid: isTokenValid,
		});
	const _put: typeof put = (url, body, options) =>
		put(url, body, {
			...options,
			authentication: auth,
			refresh: refresh,
			isTokenValid: isTokenValid,
		});
	const _patch: typeof patch = (url, body, options) =>
		patch(url, body, {
			...options,
			authentication: auth,
			refresh: refresh,
			isTokenValid: isTokenValid,
		});
	const __delete: typeof _delete = (url, options) =>
		_delete(url, {
			...options,
			authentication: auth,
			refresh: refresh,
			isTokenValid: isTokenValid,
		});
	return {
		get: _get,
		post: _post,
		put: _put,
		patch: _patch,
		_delete: __delete,
	};
};

const isGetRequest = <T>(
	request: RequestParams<T>
): request is GetRequestParams => ["GET", "DELETE"].includes(request.method);

type RequestParams<T> = GetRequestParams | DataRequest<T>;

const supportedMimeTypes = {
	json: "application/json",
	formData: "multipart/form-data",
	text: "text/plain",
};

const request = async <I, O>(params: RequestParams<I>) => {
	const headers: Record<string, string> = {
		Accept: supportedMimeTypes[params.decode],
	};
	const username = getFromLocalStorage<string>("username");
	if (params.authentication) {
		headers["Authorization"] = `Bearer ${
			params.isTokenValid(params.authentication)
				? params.authentication.accessToken
				: (await params.refresh(params.authentication, username ?? ""))
						.accessToken
		}`;
	}
	const options: RequestInit = {
		method: params.method,
		headers,
	};

	if (!isGetRequest(params)) {
		const encoder = encoders[params.encode];
		options.body = encoder(params.body as Record<any, any>);
		headers["Content-Type"] = supportedMimeTypes[params.encode];
	}

	options.headers = headers;

	const response = await fetch(params.url, options);

	if (!response.ok) {
		throw response;
	}
	return (decoders[params.decode] as any)(response) as Promise<O>;
};

const encoders = {
	json: (data: Record<any, any>) => JSON.stringify(data),
	formData: (data: Record<any, any>) => {
		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) => formData.set(key, value));
		return formData;
	},
};

const decoders = {
	json: <T>(data: Response) => data.json() as Promise<T>,
	text: <T extends string>(data: Response) => data.text() as Promise<T>,
};

type RequestOptions = Readonly<{
	encode: keyof typeof encoders;
	decode: keyof typeof decoders;
	authentication: { accessToken: string; refreshToken: string };
	refresh: (
		auth: { accessToken: string; refreshToken: string },
		username: string
	) => Promise<{
		accessToken: string;
		refreshToken: string;
	}>;
	isTokenValid: (auth: {
		accessToken: string;
		refreshToken: string;
	}) => boolean;
}>;

export const get = <URL extends keyof Endpoints["GET"]>(
	url: URL,
	options?: Partial<RequestOptions>
): Promise<Endpoints["GET"][URL]> =>
	request({
		url,
		method: "GET",
		decode: options?.decode ?? "json",
		authentication: options?.authentication,
		refresh: options?.refresh!,
		isTokenValid: options?.isTokenValid!,
	});

export const post = <
	URL extends keyof Endpoints["POST"],
	T extends Endpoints["POST"][URL]
>(
	url: URL,
	body: T["input"],
	options?: Partial<RequestOptions>
): Promise<T["ouput"]> =>
	request({
		url,
		body,
		method: "POST",
		encode: options?.encode ?? "json",
		decode: options?.decode ?? "json",
		authentication: options?.authentication,
		refresh: options?.refresh!,
		isTokenValid: options?.isTokenValid!,
	});

export const put = <
	URL extends keyof Endpoints["PUT"],
	T extends Endpoints["PUT"][URL]
>(
	url: URL,
	body: T["input"],
	options?: Partial<RequestOptions>
): Promise<T["ouput"]> =>
	request({
		url,
		body,
		method: "PUT",
		encode: options?.encode ?? "json",
		decode: options?.decode ?? "json",
		authentication: options?.authentication,
		refresh: options?.refresh!,
		isTokenValid: options?.isTokenValid!,
	});

export const patch = <
	URL extends keyof Endpoints["PATCH"],
	T extends Endpoints["PATCH"][URL]
>(
	url: URL,
	body: T["input"],
	options?: Partial<RequestOptions>
): Promise<T["ouput"]> =>
	request({
		url,
		body,
		method: "PATCH",
		encode: options?.encode ?? "json",
		decode: options?.decode ?? "json",
		authentication: options?.authentication,
		refresh: options?.refresh!,
		isTokenValid: options?.isTokenValid!,
	});

export const _delete = <URL extends keyof Endpoints["DELETE"]>(
	url: URL,
	options?: Partial<RequestOptions>
): Promise<Endpoints["DELETE"][URL]> =>
	request({
		url,
		method: "DELETE",
		decode: options?.decode ?? "json",
		authentication: options?.authentication,
		refresh: options?.refresh!,
		isTokenValid: options?.isTokenValid!,
	});
