import { useEffect, useState } from "react";
import { debounce } from "./utils";

const useWindowSize = () => {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		let mounted = true;

		const update = debounce(250)(() => {
			if (!mounted) return;
			setWidth(window.innerWidth);
		});

		window.addEventListener("resize", update);
		update();

		return () => {
			mounted = false;
			window.removeEventListener("resize", update);
		};
	}, []);

	return width;
};

export default useWindowSize;
