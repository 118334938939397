import { MenuHeader } from "components/header/header";
import { Sidebar } from "components/sidebar/sidebar";
import Flex, { CrossAxisAlignment } from "layout/flex";
import { Padding } from "layout/layout";
import Row from "layout/row";
import Prefetch from "lib/prefetch";
import { Routes } from "lib/router";
import ErrorPage from "pages/error/error-page";
import LoginPage, { useAuth } from "pages/login/login-page";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./index.scss";

const App = () => {
	const { auth } = useAuth();
	if (auth) {
		return (
			<>
				<MenuHeader />
				<Row grow crossAxisAlignment={CrossAxisAlignment.Stretch}>
					<Sidebar />
					<Flex
						as="main"
						grow
						padding={Padding.Medium}
						crossAxisAlignment={CrossAxisAlignment.Stretch}>
						<Routes />
					</Flex>
				</Row>
				<ToastContainer />
				<Row as="footer"></Row>
			</>
		);
	} else {
		return (
			<>
				<MenuHeader />
				<LoginPage />
			</>
		);
	}
};

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Prefetch
				loading={<span>Loading...</span>}
				error={error => <ErrorPage error={error} />}>
				<App />
			</Prefetch>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
