import { useTranslation } from "react-i18next";
import Column from "layout/column";
import { Gap, Padding } from "layout/layout";
import { Link } from "react-router-dom";
import { route } from "lib/router";
import { useAuth } from "pages/login/login-page";
import Row from "layout/row";
import "./sidebar.scss";
import useWindowSize from "lib/window-size";
import { useState } from "react";

export const Sidebar = () => {
	const { t } = useTranslation();
	const { logout } = useAuth();
	const windowSize = useWindowSize();
	const [show, setShow] = useState(false);

	return (
		<Column
			as="nav"
			padding={{
				horizontal: Padding.Large,
				bottom: Padding.Large,
				top: Padding.Small,
			}}
			classes={
				windowSize > 480
					? "show-desktop"
					: !show
					? "hide-mobile"
					: "show-mobile"
			}>
			{/* // classes={windowSize < 480 && !show ? "hide" : "show"}> */}
			<Column
				as="button"
				classes={["menu-btn"]}
				onClick={() => {
					setShow(!show);
				}}>
				<span className="material-symbols-outlined icon-btn">menu</span>
			</Column>
			<Column grow classes={"routes"}>
				<Column padding={{ vertical: Padding.Medium }} gap={Gap.Medium}>
					<Link to={route("register")!}>{t("pages.register.path")}</Link>
					<Link to={route("export")!}>{t("pages.export.path")}</Link>
				</Column>
				<Row>
					<button onClick={logout}>{t("pages.login.logout")}</button>
				</Row>
			</Column>
		</Column>
	);
};
